import React, { Component } from 'react';
import { SelectGenderOptions, NationalityOptions, SelectWeightClassOptions } from '../components/Options';
import { Input, Select } from '../components/layout/input';
import { post } from '../services/api';


export class AddLift extends Component {

    state = {
      canSubmit: false,
      competition: null,
      competition_date: null,
      deadlift: null,
      date_of_birth: null,
      gender: 'Male',
      name: null,
      nationality: 'Irish',
      log: null,
      weight_class: 'Open',
    }

    componentDidUpdate = (nextProps, nextState) => {
      const { competition, competition_date, deadlift, date_of_birth, gender, name, log, weight_class } = this.state;
      const fields = [competition, competition_date, deadlift, date_of_birth, gender, name, log, weight_class];

      const is_not_empty = (value) => value !== null;
      const canSubmitNew = fields.every(is_not_empty);

      if(canSubmitNew !== nextState.canSubmit){
        nextState.canSubmit = canSubmitNew;
        this.setState({ canSubmit: canSubmitNew });
      }
    }

    handleChange = name => ({ target: { value } }) => {
      this.setState({ [name]: value });
    }

    dec2hex = (dec) => {
      return dec.toString(16).padStart(2, "0")
    }
  
    // generateId :: Integer -> String
    generateId = (len = 40) => {
      var arr = new Uint8Array((len || 40) / 2)
      window.crypto.getRandomValues(arr)
      return Array.from(arr, this.dec2hex).join('')
    }
  
    getSessionId = () => {
      let sessionId = sessionStorage.getItem('dingDongSessionId') || undefined;
      if(sessionId) {
        return sessionId;
      }
  
      sessionId = this.generateId();
      sessionStorage.setItem('dingDongSessionId', sessionId)
      return sessionId;
    }
  
    getClientId = () => {
      let clientId = localStorage.getItem('dingDongClientId');
      if(clientId) {
        return clientId;
      }
  
      clientId = this.generateId();
      localStorage.setItem('dingDongClientId', clientId);
      return clientId;
    }

    handleSubmit = () => {
      const { competition, competition_date, deadlift, date_of_birth, gender, name, nationality, log, weight_class } = this.state;

      const client_id = this.getClientId();
      const session_id = this.getSessionId();

      const data = {
        competition,
        competition_date,
        date_of_birth,
        deadlift,
        gender,
        name,
        nationality,
        log,
        weight_class,
        client_id,
        session_id
      }

      post('api/lift', data).then(d => {
        // reset values
        this.setState({ competition: '', log: 0, axel: 0, deadlift: 0 });
        // trigger an event so Home/ Profile components know to refresh data
        const event = new CustomEvent("add-lift");
        document.dispatchEvent(event);
        // call parent function to close module
        this.props.closeModal();
      })
    }

    render() {
      const { canSubmit, competition, competition_date, deadlift, date_of_birth, log, name } = this.state;

      return (
          <div className="px-12 md:pt-4">
              <div className="invisible md:visible text-center">
                  Please complete all fields below
              </div>
              <Input
                label="Your name"
                onChange={this.handleChange('name')}
                value={name}
              />
              <Input
                label="Date of Birth"
                onChange={this.handleChange('date_of_birth')}
                value={date_of_birth}
                type="date"
              />

              <div className="flex">
                <div className="w-1/2">
                  <Input
                    label="Log"
                    placeholder="Weight in kg"
                    onChange={this.handleChange('log')}
                    value={log}
                  />
                </div>
                <div className="w-1/2">
                  <Input
                    label="Deadlift"
                    placeholder="Weight in kg"
                    onChange={this.handleChange('deadlift')}
                    value={deadlift}
                  />
                </div>
              </div>

              <Select
                label="Gender"
                onChange={this.handleChange('gender')}
                options={<SelectGenderOptions/>}
              />
              <Select
                label="Weight Class"
                onChange={this.handleChange('weight_class')}
                options={<SelectWeightClassOptions/>}
              />
              <Select
                label="Nationality"
                onChange={this.handleChange('nationality')}
                options={<NationalityOptions/>}
              />
              <Input
                label="Competition"
                onChange={this.handleChange('competition')}
                value={competition}
              />
              <Input 
                label="Competition Date"
                onChange={this.handleChange('competition_date')}
                value={competition_date}
                type="date"
              />
              
              <div className="text-center m-4">
                <button 
                  className={`${canSubmit ? 'bg-red-500' : 'bg-gray-300'}  px-4 py-2 text-white`}
                  // disabled={!canSubmit}
                  onClick={() => this.handleSubmit()}
                >
                  Submit
                </button>
              </div>              
          </div>
      )
    }
}
